@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Manrope";
  font-weight: 200;
  src: url('../fonts/Manrope-ExtraLight.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 300;
  src: url('../fonts/Manrope-Light.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 400;
  src: url('../fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 500;
  src: url('../fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 600;
  src: url('../fonts/Manrope-SemiBold.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 700;
  src: url('../fonts/Manrope-Bold.ttf');
}

@font-face {
  font-family: "Manrope";
  font-weight: 800;
  src: url('../fonts/Manrope-ExtraBold.ttf');
}

html,
body,
* {
  font-family: "Manrope";
}

html {
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

/* Chrome, Safari, Edge, Opera */
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-arrows {
  -moz-appearance: textfield;
}

[x-cloak] {
  display: none;
}

.elevated {
  transition: transform 0.3s ease-in-out;
}

.elevated:hover {
  transform: translateY(-5px);
}

@layer components {
  .primary-button {
    @apply text-base font-medium text-white w-36 h-11 rounded-lg bg-main-violet hover:bg-main-violet-dark-1 focus:outline-none flex items-center justify-center transition-colors;
  }

  .button-disabled {
    @apply bg-indigo-300 hover:bg-indigo-300;
  }

  .secondary-button {
    @apply rounded-md bg-white px-3.5 py-1.5 text-sm font-semibold text-blue-500 shadow-sm hover:text-blue-400 ring-1 ring-inset ring-gray-300 border border-blue-500;
  }
}

.text-center-flex {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* tailwind-like css classes for fit-content */
.h-fit-content {
  height: fit-content;
}

.w-fit-content {
  width: fit-content;
}

/* Firebase Auth UI Button */
.firebaseui-card-content {
  padding: 0 !important;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
  width: 100% !important;
  max-width: none !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 0.25rem !important;
}

/* always show input arrows */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

/* range input */
input[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*required for proper track sizing in FF*/
  background-color: #F3F4F6;
  width: 300px;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 3px;
  background: #c6c6c6;
  border: none;
  border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #000;
  margin-top: -3px;
  cursor: pointer;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]:hover {
  cursor: pointer;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}